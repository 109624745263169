<template>
  <label
    class="hm-check-box"
    :class="{
      'hm-check-box--active': modelValue,
    }"
    @click.stop="emitValue($event)"
  >
    <span v-if="$slots.default" class="hm-label-content">
      <slot></slot>
    </span>
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
    />
    <span :class="`check-mark check-mark--${styleType}`"></span>
  </label>
</template>

<script lang="ts">
import StyleType from '@/utils/typings/enums/StyleType';

export default {
  name: 'HMCheckBox',
  computed: {
    uid() {
      return this._uid;
    },
  },
  props: {
    modelValue: {
      type: Boolean,
      default: true,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    styleType: {
      type: String,
      default: StyleType.SQUARE,
    },
  },
  methods: {
    emitValue($event) {
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('update:modelValue', !this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';
.hm-check-box {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 24px;
  padding-left: 24px;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: color 0.1s ease-in;
  &--active {
    color: $charcoal;
  }
}

.hm-check-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check-mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  border: 1px solid $medium-gray;
  transition: all 0.1s ease-in;
  background-color: $white;
  &--circle {
    border-radius: 50%;
  }
}

.hm-check-box:hover input ~ .check-mark {
  background-color: $white;
}

.hm-check-box input:checked ~ .check-mark {
  background-color: var(--brand-color, $coral);
  border-color: var(--brand-color, $coral);
}

.check-mark:after {
  content: '';
  position: absolute;
  display: none;
}

.hm-check-box input:checked ~ .check-mark:after {
  display: block;
}

.hm-check-box .check-mark:after {
  left: 8px;
  top: 5px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.hm-check-box input:disabled {
  opacity: 0.5;
}

.hm-label-content {
  padding-left: 0.6rem;
}
</style>
